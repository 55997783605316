import React from "react";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faChevronDown from "@fortawesome/fontawesome-free-solid/faChevronDown";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import PostFeaturedImage from "../components/PostFeaturedImage";
import Video from "../components/Video";
import ReadMoreHTML from "../components/ReadMoreHTML";
import EntryHeader from "../components/Headers/Entry";
import ContentTheme from "../components/ContentTheme";
import InsetContent from "../components/InsetContent";
import { TagList, Tag } from "../components/TagList";
import SiteNav from "../components/SiteNav";
import SiteFooter from "../components/SiteFooter";
import Sections from "../components/Headers/Sections";

const Icon = styled(FontAwesomeIcon)`
	height: 0.5em;
	position: relative;
	top: -0.25em;

	margin-left: 10px;
	margin-right: 10px;
	animation: ${(props) =>
			props.vertical
				? "wiggle-vertical"
				: props.animated
				? "wiggle-horizontal"
				: "none"}
		ease-in-out 4s;
	animation-iteration-count: infinite;
	transform-origin: 50% 50%;

	@keyframes wiggle-vertical {
		0% {
			transform: translate(0px, 0px);
		}
		10% {
			transform: translate(0px, 5px);
		}
		20% {
			transform: translate(0px, 0px);
		}
		30% {
			transform: translate(0px, 5px);
		}
		40% {
			transform: translate(0px, 0px);
		}
		100% {
			transform: translate(0px, 0px);
		}
	}

	@keyframes wiggle-horizontal {
		0% {
			transform: translate(0px, 0px);
		}
		10% {
			transform: translate(3px, 0px);
		}
		20% {
			transform: translate(0px, 0px);
		}
		30% {
			transform: translate(3px, 0px);
		}
		40% {
			transform: translate(0px, 0px);
		}
		100% {
			transform: translate(0px, 0px);
		}
	}
`;

const HeaderImage = styled(Img)`
	height: 7em;
`;

const Article = styled.article`
	background: ${(props) => props.theme.contentBackground};
	padding-top: ${(props) => (props.image ? "0" : "2em")};
`;

const PostContent = styled(ReadMoreHTML)`
	color: ${(props) => props.theme.contentColor};
`;

const ProjectPreface = styled.div`
	margin-top: 2em;
`;

const ProjectTitle = styled.h1`
	display: block;
	text-align: center;
	padding-bottom: 1em;
	padding-top: 1em;
	background: ${(props) => props.theme.navigationBarBackground};
	border-top: ${(props) => props.theme.navigationBarBorder};
	border-bottom: ${(props) => props.theme.navigationBarBorder};
`;

const Project = ({ node }) => {
	const title = get(node, "frontmatter.title");
	return (
		<ContentTheme key={node.id} layout="post">
			<Article layout="post" className="layout-post">
				<EntryHeader
					slug={node.fields.slug}
					title={title}
					date={node.frontmatter.date}
					link={node.frontmatter.link}
					image={
						node.frontmatter.image &&
						node.frontmatter.image.childImageSharp.gatsbyImageData
					}
				/>
				<InsetContent>
					{node.frontmatter.video_url && (
						<section>
							<Video url={node.frontmatter.video_url} />
						</section>
					)}
					<PostContent
						className="e-content"
						html={node.html}
						slug={node.fields.slug}
						link={node.frontmatter.link}
					/>
					{node.frontmatter.tags && (
						<TagList>
							{node.frontmatter.tags.map((tag) => (
								<Tag key={tag} tag={tag} />
							))}
						</TagList>
					)}
				</InsetContent>
			</Article>
		</ContentTheme>
	);
};

class AllProjects extends React.Component {
	render() {
		const siteTitle = get(this, "props.data.site.siteMetadata.title");
		const image = get(this, "props.data.header");
		const projects = get(this, "props.data.projects.edges") || [];
		const activeProjects = projects.filter(
			(project) =>
				get(project, "node.frontmatter.active") || false === true
		);
		const inactiveProjects = projects.filter(
			(project) => activeProjects.indexOf(project) === -1
		);
		const permalink = "/projects/";
		return (
			<Layout>
				<div>
					<Helmet title={"Projects | " + siteTitle} />
					{permalink && (
						<Helmet key="permalink">
							<link
								rel="self"
								href={"https://stevestreza.com" + permalink}
							/>
						</Helmet>
					)}
					<SiteNav />
					{image && (
						<PostFeaturedImage
							height={"7em"}
							image={image.childImageSharp.gatsbyImageData}
						/>
					)}
					<Sections />

					<ProjectPreface>
						<InsetContent>
							<p>
								Below is a list of projects I work on, whether
								they're open source code, apps, videos, computer
								builds, hardware projects, construction
								projects, or really anything else. I have an
								ever-expanding list of hobbies and skills to
								learn and love to share the process of learning
								with others!
							</p>
							<p>
								Projects that I consider to be still in the
								works are considered <strong>active</strong>.
								Projects that have been discontinued, retired,
								abandoned, or otherwise are not.
							</p>
						</InsetContent>
					</ProjectPreface>

					{activeProjects.length > 0 && (
						<ProjectTitle>
							Active Projects{" "}
							<Icon
								icon={faChevronDown}
								animated={true}
								vertical={true}
							/>
						</ProjectTitle>
					)}
					{activeProjects.map((project) => (
						<Project key={project.node.id} node={project.node} />
					))}

					{inactiveProjects.length > 0 && (
						<ProjectTitle>
							Inactive Projects{" "}
							<Icon
								icon={faChevronDown}
								animated={true}
								vertical={true}
							/>
						</ProjectTitle>
					)}
					{inactiveProjects.map((project) => (
						<Project key={project.node.id} node={project.node} />
					))}
					<SiteFooter />
				</div>
			</Layout>
		);
	}
}

export const query = graphql`
	query AllProjects {
		site {
			siteMetadata {
				title
				author
			}
		}

		projects: allMarkdownRemark(
			filter: { frontmatter: { type: { eq: "project" } } }
		) {
			edges {
				node {
					html
					fields {
						slug
					}
					frontmatter {
						title
						status
						permalink
						active
					}
				}
			}
		}

		header: file(relativePath: { eq: "header/header.png" }) {
			...ImageData
		}
	}
`;

export default AllProjects;
